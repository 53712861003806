<script>
   
  </script>
  
  <main>
    <div class="container">
      <h1>Désolé, cette page est introuvable</h1>
      <p>Nous sommes désolés, mais la page que vous recherchez semble introuvable. Il est possible qu'elle ait été déplacée, renommée ou qu'elle n'existe plus.</p>
      <p>Voici quelques options pour vous aider à trouver ce que vous cherchez :</p>
      <ol>
        <li>Vérifiez l'URL: Assurez-vous d'avoir saisi correctement l'adresse URL. Même une petite faute de frappe peut entraîner l'impossibilité de trouver la page.</li>
        <li>Retournez à la <a href="/">page d'accueil</a></li>
      </ol>
    </div>
  </main>
  
  <style>
    .container {
        height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  margin-top: 6em;
    
      
      text-align: center;
      padding: 2.5em;
    }
  
    h1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
  
    p {
      font-size: 18px;
      margin-bottom: 15px;
    }
  
    ol {
      text-align: left;
      margin-left: 40px;
      margin-bottom: 20px;
    }
  
    a {
      color: #007bff;
      text-decoration: none;
    }
  </style>
  